<template>
    <div>
        <Horizontal ref="Horizontal" v-if="$store.getters.currentPageVersion === 'Horizontal'" @getPhoneCode="getPhoneCode" :codecd="codecd" @subForm="subForm"></Horizontal>
        <Vertical ref="Vertical" v-else @getPhoneCode="getPhoneCode" :codecd="codecd" @subForm="subForm"></Vertical>
    </div>
</template>

<script>
    import Horizontal from '@/components/Mine/AccountAdmin/Horizontal/Index.vue'
    import Vertical from '@/components/Mine/AccountAdmin/Vertical/Index.vue'
    
    export default {
        components:{
            Horizontal,
            Vertical
        },
        data() {
            return {
                codecd: 0,
                isCanSub:true,
                isTabBtn: 0
            }
        },
        methods:{
            getPhoneCode() {
                if(this.$common.timeFunc().timeStampS- this.isTabBtn <7) {
                    this.$Toast({
                        message: '请勿频繁操作',
                    })
                    return
                }else {
                    this.isTabBtn = this.$common.timeFunc().timeStampS
                }
                if(!this.$store.state.bindPhone) {
                    this.$Toast({
                    	message: '手机号获取失败',
                    })
                    return
                }
               
                let that = this,
                    requestMethods = this.$store.state.isSettingAccountPassword===1?'getChangeAccountPasswordCode':'getSetAccountPasswordCode',
                    requestData={
                        user_id:this.$store.state.userId,
                        token:this.$store.state.token,
                        code_type:this.$store.state.isSettingAccountPassword===1?'sdk_modify_password':'sdk_bind_password'
                    }
                eval(`this.$api.user.${requestMethods}(${JSON.stringify(requestData)})`).then(res=> {
                    if (res.data.code === 200) {
                         this.codecd = 60
                         let cdTimer = setInterval(() => {
                             that.codecd = that.codecd - 1
                             if (that.codecd < 1) {
                                 clearInterval(cdTimer)
                             }
                         }, 1000)
                        this.$Toast({
                        	message: '验证码已发送',
                        })
                    }
                })
                
            },
            subForm(data) {
                if(!this.checkRequestData(data)) {
                    return
                }
                this.isCanSub = false
                setTimeout(()=> {
                    this.isCanSub = true
                },5000)
                let requestMethods = this.$store.state.isSettingAccountPassword===1?'changeAccountPassword':'setAccountPassword',
                    requestData={
                        user_id:this.$store.state.userId,
                        token:this.$store.state.token,
                        phone_code:data.inputCode,
                        password:data.inputPassword
                    },
                successMsg=this.$store.state.isSettingAccountPassword===1?'密码修改成功！':'密码设置成功！'
               
                eval(`this.$api.user.${requestMethods}(${JSON.stringify(requestData)})`).then(res=> {
                    if (res.data.code === 200) {
                        this.$Toast({
                        	message: successMsg,
                        })
                        setTimeout(()=> {
                            if(this.$store.state.isSettingAccountPassword===1) {
                                 this.$common.logout()
                            }else {
                                this.$store.commit('getAccountPasswordSetState', 1)
                                this.$router.push({
                                    name:'MineIndex'
                                })
                            }
                           
                        },2000)
                    }
                })
            },
            checkRequestData(data) {
                console.log(data)
                if(!data.inputPassword || !data.inputCode) {
                    this.$Toast({
                    	message: '密码和验证码不能为空',
                    })
                    return false
                }
                if(!this.isCanSub) {
                    this.$Toast({
                    	message: '请勿频繁操作',
                    })
                    return false
                }
                if(data.inputPassword.length<6 ||
                   data.inputPassword.length>17||
                   data.inputPassword.indexOf(" ") !== -1||
                   data.inputCode.length !== 6 ||
                   data.inputCode.indexOf(" ") !== -1) {
                       this.$Toast({
                       	message: '密码或验证码格式不正确',
                       })
                       return false
                   }
                return true
            },
        }
    }
</script>

<style>
</style>