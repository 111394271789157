<template>
    <div>
       <TopNav :title="$store.state.isSettingAccountPassword===1?'修改登陆密码':'账号密码管理'" backPath="Mine"></TopNav>
       <div class="tips">
           我们将发送验证码到<span>{{$store.state.bindPhone}}</span>手机上
       </div>
       <div class="form-box">
           <div class="input-box-h">
               <input v-model="dataPack.inputCode" @input="dataPack.inputCode=dataPack.inputCode.substr(0,6)" type="number" placeholder="请输入验证码" class="input-h">
               <div class="get-code-cd" v-if="codecd>0">
                   {{'重新发送('+ codecd+ ')'}}
               </div>
               <div class="get-code-box" @click="getPhoneCode" v-else>
                   获取验证码
               </div>
           </div>
           <div class="input-box-h">
               <input v-model="dataPack.inputPassword" type="text" placeholder="请设置密码" class="input-h">
               
           </div>
           <div class="input-tips">
               *大小写字母、数字、常见符号组合6-16位密码
           </div>
       </div>
       <div class="btn-box" @click="subForm">
          确 认
       </div>
       <div class="buttom-tips-change" v-if="$store.state.isSettingAccountPassword===1">
          为了您账号的安全，修改密码需要验证手机号
       </div>
       <div class="buttom-tips" v-else>
          您注册时使用的验证码登录,可设置账号密码,手机号作
          为账号采用账号密码登录
       </div>
    </div>
</template>

<script>
    import TopNav from "@/components/Layout/TopNav.vue"
    export default {
        components: {
          TopNav
        },
        props:{
          codecd:Number
        },
        data() {
            return {
                dataPack:{
                    inputCode:'',
                    inputPassword:''
                }
            }
        },
        methods: {
            getPhoneCode() {
                this.$emit('getPhoneCode')
            },
            subForm() {
                this.$emit('subForm', this.dataPack)
            }
        }
    }
</script>

<style scoped>
    .tips {
        font-size: 8.75px;
        font-weight: 400;
        color: #666666;
        text-align: center;
    }
    .tips>span {
        color: #16C5CE;
    }
    .form-box {
        padding: 8.75px 0;
    }
    .get-code-box {
        font-size: 8.75px;
        color: #16C5CE;
    }
    .input-tips {
        font-size: 7.5px;
        font-weight: 400;
        color: #333333;
    }
    .btn-box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 26.25px;
        background: #16C5CE;
        border-radius: 4px;
        font-size: 10px;
        font-weight: 400;
        color: #FFFFFF;
    }
    .buttom-tips,
    .buttom-tips-change {
        width: 100%;
        padding-top: 20px;
        display: flex;
        align-items: flex-end;
        font-size: 7.5px;
        font-weight: 400;
        color: #FF7400;
    }
    .buttom-tips-change {
        font-size: 7.5px;
        font-weight: 400;
        color: #999999;
    }
</style>
